<template>
  <div>

    <v-row dense>
      <v-col cols="4">
        <select-material-warehouse-model v-model="id_material_warehouse"
          @change="onWarehouseChange"></select-material-warehouse-model>
      </v-col>
      <v-col cols="8">
        <input-qr-scan-model v-model="code" ref="codeRef" :label="placeholder" @keyupEnter="inputScan"
          :auto-close="false"></input-qr-scan-model>
      </v-col>
    </v-row>

    <div class="mt-3">
      <v-alert color="error" text dark class="text-center">
        <span class="fs-16">{{ $t('labels.current_position') }}: </span>
        <span class="text-h5">{{ location_code }}</span>
      </v-alert>
    </div>

  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "SetPositionFree",
  components: {

  },
  props: {},
  data: () => ({
    isLoading: false,
    code: null,
    id_material_warehouse: null,
    location_code: null,
  }),
  computed: {
    step() {
      if (!this.location_code) {
        return 1
      } else {
        return 2
      }
    },
    placeholder() {
      switch (this.step) {
        case 1:
          return this.$t('labels.location_code')
        case 2:
          return this.$t('labels.uid_new_position')
        default:
          return '--- ERROR ---'
      }
    },
  },
  mounted() {

  },
  methods: {
    onWarehouseChange() {
      if (!this.id_material_warehouse) {
        return false
      }
      this.$nextTick(() => {
        this.$refs.codeRef.focusInput()
        this.restartAll()
      })
    },

    restartAll() {
      this.isLoading = false
      this.code = null
      this.location_code = null
    },

    async inputScan() {
      if (!this.code) {
        return false
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        const { data } = await httpClient.post('/get-location-or-uid', {
          code: this.code,
          id_material_warehouse: this.id_material_warehouse
        })
        this.isLoading = false
        if (data.location) {
          this.location_code = data.location.cell_id
          this.code = null
          this.$root.$emit('playSuccessAudio')
        } else if (data.item) {
          await this.scanUid()
        }
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
        this.code = null
        this.isLoading = false
        this.$root.$emit('playErrorAudio')
      }
    },

    async scanUid() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        await httpClient.post('/item-to-location-stowing', {
          code: this.code,
          id_material_warehouse: this.id_material_warehouse,
          location_code: this.location_code
        })
        this.code = null
        this.isLoading = false
        this.$root.$emit('playSuccessAudio')
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
        this.code = null
        this.isLoading = false
        this.$root.$emit('playErrorAudio')
      }
    },
  }
}
</script>

<style scoped></style>
